import Konva from "konva";
import _, { cloneDeep } from "lodash";
import { appendTableToList } from "../builderSetup";
import { isEmptySeats } from "../utilsEdit";
import { TextParams } from "../Constants";
import { deselectGroup, setId } from "../utils";
import { AppStage } from "@/core/AppStage";
import { mainGroups } from "@/core/MainGroups";
import { seatsGroups } from "@/core/SeatsGroup";

import {
  roundTableBind,
  rectangleTableBind,
  mergeArrays,
  squareTableBind,
} from "./utils";

import {
  deleteSeat,
  updateSeat
} from '../seatUtils'
import { usedIds } from "@/core/UsedIds";

let builder;
let borderLayer = {};
let cloneTables = {};

export const createTable = (
  seatsBind,
  configs,
  component,
  tableId,
  position
) => {
  const appStage = new AppStage();
  const { seats, name, type, rotation, hideTitle } = configs;
  builder = component;
  const id = tableId ? tableId : setId();
  if(tableId) {
    usedIds.add(tableId);
  }

  const draggable = configs.draggable !== undefined ? configs.draggable : true;

  let mainGroup = new Konva.Group({
    name,
    id,
    draggable: draggable,
    rotation: 0,
    category: 'seats'
  });

  mainGroup.setAttrs({
    type,
    configs,
    seatsBind,
  });

  // for set actions position
  const borderOptions = {
    strokeWidth: 1,
    dash: [5, 5],
    type: "border",
  }

  let layerBorder;

  if (type === "round") {
    const { roundTableGroup, mainSeatGroup } = roundTableBind(
      seatsBind,
      id,
      tableId,
      component,
      configs
    );
    
    mainSeatGroup.setAttrs({ group: "seatGroup" });

    seatsGroups.add(mainSeatGroup, id);
    mainGroup.add(roundTableGroup);

    layerBorder = new Konva.Circle();

    mainGroup.seatsBind = seatsBind;
   
  } else if (type === "rectangle") {
    if(!configs.width) {
      configs.width = 100
    }

    if(!configs.sides || configs.sides.length < 2) {
      configs.sides = [seatsBind.length, 0];
    }

    const { rectangleTableGroup, seatGroup } = rectangleTableBind(
      seatsBind,
      id,
      tableId,
      component,
      configs
    );

    seatGroup.setAttrs({ group: "seatGroup" });

    seatsGroups.add(seatGroup, id);

    mainGroup.add(rectangleTableGroup);
    const tableBound = rectangleTableGroup.getClientRect();

    layerBorder = new Konva.Rect({
      offsetX: tableBound.width / 2,
      offsetY: tableBound.height / 2,
      x: -10,
      y: -10,
    });

    mainGroup.seatsBind = seatsBind;

  } else if (type === "square") {
    const { squareTableGroup, seatsGroup } = squareTableBind(
      seatsBind,
      id,
      tableId,
      component,
      configs
    );

    mainGroup.add(squareTableGroup);
    squareTableGroup.add(seatsGroup);
    mainGroup.seatsBind = seatsBind;
    const tableBound = squareTableGroup.getClientRect();

    layerBorder = new Konva.Rect({
      offsetX: tableBound.width / 2,
      offsetY: tableBound.height / 2,
      x: -10,
      y: -10,
    });

    seatsGroup.setAttrs({ group: "seatGroup" });
    seatsGroups.add(seatsGroup, id);
  }

  let tableName = new Konva.Text({
    text: configs.upperCase ? name.toUpperCase() : name,
    fill: "white",
    align: TextParams.ALIGN_CENTER,
    verticalAlign: "middle",
    type: "name",
    fontFamily: !configs.bold ? TextParams.FONT_REGULAR : TextParams.FONT_BOLD,
    fontSize: configs.fontSize ? configs.fontSize : TextParams.SIZE_XL,
    lineHeight: configs.lineHeight,
    letterSpacing: configs.spacing,
  });

  mainGroup.add(tableName);
  mainGroup.add(layerBorder);
  const seatsGroupBound = mainGroup.getClientRect();

  tableName.setAttrs({

    offsetX: tableName.width() / 2,
    offsetY: tableName.height() / 2,

  });

  

  if(hideTitle === true) tableName.visible(false);

  layerBorder.setAttrs({
    ...borderOptions,
    width: seatsGroupBound.width + 20,
    height: seatsGroupBound.height + 20,
  });

  borderLayer[id] = layerBorder;
  mainGroups.add(mainGroup, id);
  

  if (configs.rotation && rotation !== null)
      rotateTable(rotation, id, configs);

  if (position) {
    mainGroup.setAttrs({
      x: position.x,
      y: position.y,
      offsetX: mainGroup.width() / 2,
      offsetY: mainGroup.height() / 2,
    });
  } else {
    const scale = appStage.scale();

    mainGroup.position({
      x: (((-appStage.x() + appStage.width() / 2) / scale.x)),
      y: (((-appStage.y() + 40) / scale.y + (seatsGroupBound.height / 2))),
    });
  }

  if(appStage.editing) {
    borderLayer[id].moveToBottom();
  }
  
  return { mainGroup };
};

export const tableActions = {
  editAction: (id, component, configs) => {
    const currentGroup = mainGroups.get(id);
    cloneTables[id] = _.cloneDeep(currentGroup.seatsBind);
    AppStage.addKey('editing', id);
    const conf = currentGroup.attrs.configs;

    component.editTable(id, conf, currentGroup);
    
    borderLayer[id].moveToBottom();
    borderLayer[id].setAttrs({stroke: ''});
  },
  copyAction: (id, component, configs) => {
    const appStage = new AppStage();
    const mainLayer = appStage.children[0];
    const currentGroup = mainGroups.get(id);
    const conf = currentGroup.attrs.configs;
    const updatedConf = {...conf, draggable: true};
    const { seatsBind } = currentGroup;
    let newSeatsBind = _.cloneDeep(seatsBind);
    for(let i = 0; i < seatsBind.length; i++) {
      newSeatsBind[i].id = setId();
    }
    
    const { mainGroup } = createTable(newSeatsBind, updatedConf, builder);
    mainLayer.add(mainGroup);

    appendTableToList(mainGroups);
    deselectGroup();

  }
}

export const updateTableStage = (id, payload) => {
  const appStage = new AppStage();
  const mainLayer = appStage.children[0];
  let currentGroup = mainGroups.get(id);
  const position = currentGroup.position();
  const { type } = payload.tableData;
  
  const { seats, start } = payload.newData;
  const { seatsBind } = currentGroup;

  let bindSeats = seatsBind;
  let seatsLength = seats;
  const seatsBindNew = [];

  for (let i = 0; i < seatsLength; i++) {
    seatsBindNew.push({ id: setId(), number: i + +start });
  }

  const mergedArr = mergeArrays(seatsBind, seatsBindNew);
  const isEmpty = isEmptySeats.table(mergedArr);
  bindSeats = mergedArr;

  if (isEmpty) {
    return false;
  }

  currentGroup.destroy();
  const { mainGroup } = createTable(
    bindSeats,
    payload.newData,
    builder,
    id,
    position
  );

  mainLayer.add(mainGroup);
  mainGroups.add(mainGroup, id);
  currentGroup = mainGroups.get(id);
  // selectSeats(id, builder, conf);
 
  if(appStage.editing && currentGroup.attrs.draggable) {
    currentGroup.setAttrs({ 
      draggable: false,
      editDrag: true
    });
  }

};

export const editTableSeat = (payload, type) => {
  const { layerId } = payload.seatData;
  const currentGroup = mainGroups.get(layerId);
  const seatsBind = currentGroup.seatsBind;
  updateSeat(seatsBind, payload, type);
};

export const copyTable = (component, configs, id) => {
  const currentGroup = mainGroups.get(id);
  const appStage = new AppStage();
  const mainLayer = appStage.children[0];
  const { seatsBind, attrs } = currentGroup;
  const { mainGroup } = createTable(seatsBind, attrs.configs, builder);
  mainLayer.add(mainGroup);

  appendTableToList(mainGroups);
};


export const deleteTableSeat = (payload) => {

  const { layerId } = payload.seatData; // , configs
  let currentGroup = mainGroups.get(layerId);
  const appStage = new AppStage();
  const mainLayer = appStage.children[0];
  const { configs } =  currentGroup.attrs;
  const { seatsBind } =  currentGroup;
  const position =  currentGroup.position();
  const changedSeatsBind = deleteSeat(seatsBind, payload);

  currentGroup.destroy();

  const { mainGroup } = createTable(
    changedSeatsBind,
    configs, 
    builder,
    layerId,
    position
  );

  currentGroup = mainGroup;
  mainLayer.add(mainGroup);

 
};



export const cancelTableChanges = (configs) => {
  const appStage = new AppStage();
  const mainLayer = appStage.children[0];
  const layerId = configs?.layerId;
  const currentGroup = mainGroups.get(layerId);
  const confs = currentGroup.attrs.configs;
  const cloneData = cloneTables[layerId];
  const position =currentGroup.position();
  currentGroup.destroy();

  const { mainGroup } = createTable(
    cloneData,
    configs,
    builder,
    layerId,
    position
  );

  mainLayer.add(mainGroup);

  deselectGroup();
};

export const rotateTable = (rotation, id, configs) => {
  const currentGroup = mainGroups.get(id);
  const currentSeats = seatsGroups.get(id)
  currentGroup.rotation(rotation);

  let oppositeRotation = -rotation;
  let seats = currentSeats.getChildren();
  let groupChild = currentGroup.getChildren();
  const tableName = groupChild.find((g) => g.attrs.type === "name");

  tableName.rotation(oppositeRotation);

  if(borderLayer[id].getClassName() === 'Circle') {
    borderLayer[id].rotation(oppositeRotation);
  }

  for (let i = 0; i < seats.length; i++) {
    const element = seats[i].children[1];
    let defRotation = currentGroup.attrs.type === 'round' ? i * 360 / seats.length + 90 : 0;
    element.rotation(oppositeRotation - defRotation);
  }

  configs = { ...configs, rotation };
  currentGroup.setAttrs({ configs });
};

export const showHideTitle = ({ hideTitle, layerId }) => {
  const currentGroup = mainGroups.get(layerId);
  const confs = currentGroup.attrs.configs;
  const text = currentGroup.children.find(g => g.attrs.type === "name");
  if (text) text.visible(!hideTitle);
  const updateConfigs = { ...confs, hideTitle: hideTitle };
  currentGroup.setAttrs({ configs: updateConfigs });
}

export const saveChanges = (id) => {
  borderLayer[id].moveToTop();
}

export const changeNumbering = (id, newData) => {
  const currentGroup = mainGroups.get(id);
  const { start } = newData;
  const newSeatsBind = _.cloneDeep(currentGroup.seatsBind);
  for(let i = 0; i < newSeatsBind.length; i++) {
    newSeatsBind[i].number = +start + i;
  }
  currentGroup.seatsBind = newSeatsBind;
}